.head-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 60px !important;
}
.logo-cont {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.logo-cont .logo-img {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
.logo-cont .title {
  display: block;
  font-size: 20px;
  color: #007fff;
}
.user-box {
  display: flex;
  align-items: center;
}
.user-box .desc-text {
  margin-right: 24px;
  font-size: 14px;
  color: #000000;
  cursor: pointer;
}
