/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-size: 16px;
  font-family: PingFangSC-Regular, SFHeitiSC, 'microsoft yahei', 'Helvetica Neue', Helvetica, STHeiTi, sans-serif;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* custom */
a {
  color: #007fff;
  text-decoration: none;
  outline: 0;
  -webkit-backface-visibility: hidden;
}
a:hover {
  color: #007fff;
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-track-piece {
  background-color: #f2f2f2;
  -webkit-border-radius: 6px;
}
::-webkit-scrollbar-thumb:vertical {
  height: 3px;
  background-color: #e5e5e5;
  -webkit-border-radius: 6px;
}
::-webkit-scrollbar-thumb:horizontal {
  width: 3px;
  background-color: #e5e5e5;
  -webkit-border-radius: 6px;
}
html,
body {
  width: 100%;
  height: 100%;
  position: relative;
}
body {
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #f8f8fc;
  min-width: 1500px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,
li {
  list-style-type: none;
}
/*tencent mobile reset css*/
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input::-webkit-input-placeholder {
  color: #999;
}
/*cancel input border radius*/
input,
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
th {
  text-align: left;
}
.clearfix:after {
  content: '\200B';
  display: block;
  height: 0;
  clear: both;
}
.clearfix {
  *zoom: 1;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
input {
  border: 1px solid #cccccc;
  border-radius: 2px;
  height: 28px;
  line-height: 28px;
  outline: none;
  font-size: 14px;
  color: #262626;
}
input:-internal-autofill-selected {
  color: #262626;
  background-color: #ffffff !important;
  background-image: none !important;
}
::-webkit-scrollbar {
  width: 8px;
}
/* 投放时间段 */
.hour-set .ant-form-item-control-input-content {
  height: 30px;
  padding-top: 10px;
  overflow: hidden;
}
.hour-set .ant-checkbox + span {
  padding: 0;
  margin: 0;
  color: #fff;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #ccc;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
}
.hour-set .check-all {
  float: left;
  margin-top: 11px;
  margin-right: 8px;
}
.hour-set .check-all .ant-checkbox {
  display: none;
}
.hour-set .check-all span {
  width: 40px;
}
.hour-set .ant-checkbox-wrapper {
  align-items: inherit;
}
.hour-set .ant-checkbox-wrapper-checked span {
  background: #007fff;
}
.hour-set .ant-checkbox-inner {
  display: none;
}
.hour-set .ant-checkbox-input {
  display: none;
}
.hour-set .ant-checkbox-group-item {
  min-width: 20px;
}
/* 广告任务创建-组合表单控件重写 */
.common-space-group-box.ant-form-item {
  margin-bottom: 0;
}
.common-space-group-box .ant-input-group-compact .ant-form-item + .ant-form-item {
  margin-left: 15px;
}
.common-space-group-box .common-group-btn {
  height: 32px;
  line-height: 32px;
  margin-left: 10px;
}
/* 输入框-带提示语 */
.item_control {
  width: 100%;
  position: relative;
}
.item_control .ant-form-item-label {
  width: 16.66666667%;
}
.item_control .ant-form-item-control {
  width: 280px;
  flex: 0 0 25%;
  max-width: 280px;
}
.item_control .tips_box {
  position: absolute;
  top: 10px;
  left: 39%;
}
.item_control .tips_box .unit {
  color: #7e7e7e;
}
.item_control .tips_box .tips {
  margin-left: 24px;
  color: #b2b2b2;
}
/* 单选按钮-大按钮带描述 */
.radio_btn {
  width: 156px;
  height: 86px;
  padding: 4px 12px;
  border-radius: 4px;
  border-inline-start-width: 1px;
  font-size: 14px;
  font-size: 600;
}
.radio_btn.ant-radio-button-wrapper::before {
  width: 0;
}
.radio_btn.ant-radio-button-wrapper:hover .radio_desc {
  color: #007fff;
}
.radio_btn .radio_desc {
  display: block;
  margin-top: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
}
.radio_btn.ant-radio-button-wrapper-checked .radio_desc {
  color: #fff !important;
}
.radio_btn + .radio_btn {
  margin-left: 8px;
}
/* 步骤条组件-创建广告排期 */
.step_info .ant-steps-item-content .ant-steps-item-title {
  font-weight: 600;
  color: #262626;
}
.step_info .description {
  font-size: 12px;
  color: #b2b2b2;
}
.step_info .description p {
  padding: 4px 0;
}
/* 连续并排按钮 */
.btn_box {
  margin: 30px 0;
}
.btn_box button {
  width: 90px;
  height: 32px;
}
.btn_box button + button {
  margin-left: 20px;
}
.a_link {
  cursor: pointer;
  color: #007fff;
}
/* 全局布局样式 */
.content_box {
  margin-bottom: 20px;
  background-color: #fff;
}
.content_box.indent {
  padding: 14px 24px;
}
/* 首页看版 */
.home-page .ant-statistic-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.table-no-description .ant-table-empty .ant-empty-description {
  display: none !important;
}
.list-container {
  height: 100% !important;
  background-color: white;
  padding: 10px;
}
.ant-descriptions-row {
  display: flex !important;
}
.ant-descriptions-row .ant-descriptions-item-label {
  width: 145px !important;
  flex-shrink: 0;
}
.ant-descriptions-row .ant-descriptions-item-content {
  flex: 1;
}
.ant-descriptions-row .ant-collapse {
  margin-left: -12px;
}
#html {
  /* 菜单导航 */
  /* 搜索查询表单控件 */
  /* 表格 */
  /* 卡片 */
  /* 表格操作控件样式 */
  /* 折叠面板 */
  /* 模态框 */
}
#html .ant-layout {
  background: #f8f8fc;
  min-height: 100vh;
}
#html .head-dropmenu-menu-item .ant-menu-light .ant-menu-item-selected {
  background-color: #fff;
}
#html .head-dropmenu-menu-item .ant-dropdown {
  font-size: 16px;
}
#html .head-dropmenu-menu-item .ant-dropdown-menu-title-content > span {
  font-weight: 500;
}
#html .head-dropmenu-menu-item .ant-dropdown-menu-item:hover {
  background-color: #fff;
}
#html .head-dropmenu-menu-item .ant-dropdown-menu-item.active {
  background-color: #fff;
}
#html .head-dropmenu-menu-item .ant-dropdown-menu-item-active {
  background-color: #fff;
}
#html .head-dropmenu-menu-item .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected,
#html .head-dropmenu-menu-item .ant-menu-light.ant-menu-horizontal > .ant-menu-item-active {
  color: #000;
}
#html .head-dropmenu-menu-item .ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: 0;
}
#html .head-dropmenu-menu-item .ant-menu-vertical .ant-menu-item {
  padding-inline: 0;
  margin-inline: 0;
  margin-block: 4px;
  width: 100%;
}
#html .head-dropmenu-menu-item .ant-menu-item:hover {
  background-color: #fff;
}
#html .head-dropmenu-menu-item .ant-menu-light .ant-menu-item-selected {
  color: #000;
  background-color: #fff;
}
#html .head-dropmenu-menu-item .ant-menu-item-active {
  color: #007fff;
  background-color: #fff;
}
#html .head-dropmenu-menu-item .head-menu-sub-item.active {
  color: #007fff !important;
}
#html .head-dropmenu-menu-item .head-menu-sub-item.disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}
#html .head-menu .ant-dropdown-trigger .anticon {
  color: #aaa;
  margin-left: 4px;
}
#html .head-menu .head-menu-item.active {
  color: #007fff !important;
}
#html .head-menu .head-menu-item.disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}
#html .ant-form-inline .ant-form-item {
  margin-bottom: 20px;
}
#html .ant-form-item .ant-form-item-label > label::after {
  content: '';
}
#html .ant-input {
  height: 20px;
}
#html .ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #f8f8fc;
}
#html .ant-table-wrapper .ant-table-container,
#html .ant-table-wrapper table {
  border-radius: 4px;
  border-start-start-radius: 4px;
  border-start-end-radius: 4px;
}
#html .ant-table-wrapper .ant-table-container thead > tr:first-child > *:last-child,
#html .ant-table-wrapper table thead > tr:first-child > *:last-child {
  border-radius: 4px;
  border-start-start-radius: 4px;
  border-start-end-radius: 4px;
}
#html .ant-card {
  box-shadow: none;
  /* 嵌套卡片 */
}
#html .ant-card .ant-card .ant-card-head {
  border-bottom: none;
  padding: 0;
}
#html .ant-card .ant-card .ant-card-body {
  padding: 0;
}
#html .ant-badge .ant-badge-status-dot {
  width: 8px;
  height: 8px;
  margin-right: 4px;
}
#html .list_operate .ant-btn-link {
  padding: 0px 10px;
}
#html .list_operate .ant-btn-link:first-child {
  padding-left: 0px;
}
#html .ant-modal-content {
  padding: 0 !important;
}
#html .ant-modal-content .ant-modal-close {
  top: 10px;
}
#html .ant-modal-content .ant-modal-header {
  border-bottom: 1px solid #cccccc !important;
  padding: 10px 10px 10px 20px;
}
#html .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 20px !important;
}
#html .ant-modal-content .ant-modal-close-x {
  font-size: 20px !important;
}
#html .ant-modal-content .ant-modal-body {
  padding: 12px 20px !important;
}
#html .ant-modal-content .ant-modal-footer {
  padding: 0 20px 10px 0;
}
/* 模态框 */
.ant-modal-content {
  padding: 0 !important;
}
.ant-modal-content .ant-modal-close {
  top: 10px;
}
.ant-modal-content .ant-modal-header {
  border-bottom: 1px solid #cccccc !important;
  padding: 10px 10px 10px 20px;
}
.ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 20px !important;
}
.ant-modal-content .ant-modal-close-x {
  font-size: 20px !important;
}
.ant-modal-content .ant-modal-body {
  padding: 12px 20px !important;
}
.ant-modal-content .ant-modal-footer {
  padding: 0 20px 10px 0;
}
/* 表格 */
.ant-table-container .ant-table-thead .ant-table-cell .table-title-text-wrap {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.ant-table-container .ant-table-thead .ant-table-cell .table-title-text-wrap svg:first-of-type {
  margin-right: 4px;
  flex-shrink: 0;
}
.ant-table-container .ant-table-tbody .ant-table-cell {
  vertical-align: middle;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb4 {
  margin-bottom: 4px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.ml4 {
  margin-left: 4px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.mr4 {
  margin-right: 4px !important;
}
.mr8 {
  margin-right: 8px !important;
}
.mt4 {
  margin-top: 4px !important;
}
.mt8 {
  margin-top: 8px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.width14 {
  width: 14px !important;
}
.width50 {
  width: 50px !important;
}
.width90 {
  width: 90px !important;
}
.width140 {
  width: 140px !important;
}
.width150 {
  width: 150px !important;
}
.width200 {
  width: 200px !important;
}
.width230 {
  width: 230px !important;
}
.width260 {
  width: 260px !important;
}
.width400 {
  width: 400px !important;
}
.width430 {
  width: 430px !important;
}
.width900 {
  width: 900px !important;
}
.bold {
  font-weight: bold !important;
}
.fs10 {
  font-size: 10px;
}
.fs12 {
  font-size: 12px;
}
.fs20 {
  font-size: 20px;
}
.color-gray {
  color: gray;
}
.color-blue {
  color: #007fff;
}
.color-red {
  color: red;
}
.flex {
  display: flex;
}
.flex-end {
  justify-content: end;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.flex-column {
  flex-direction: column;
}
.cursor-pointer {
  cursor: pointer;
}
