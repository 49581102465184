.layout-container .content-warpper {
  margin: 20px 180px;
}
.layout-container .content-spin {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.layout-container .ant-layout-container {
  padding: 12px 12px 12px 0;
}
.layout-container .spin-wrapper {
  width: 100%;
  height: 100%;
}
.layout-container .spin-wrapper .ant-spin-container {
  width: 100%;
  height: 100%;
}
.layout-container .caidata-sider {
  position: relative;
}
.layout-container .caidata-sider .collapse-button {
  border-radius: 2px;
  position: absolute;
  right: -20px;
  top: 40vh;
  width: 20px;
  height: 40px;
  display: flex;
  padding: 0;
  z-index: 1;
  border: none;
  background-color: #a8b0bf;
}
.layout-container .caidata-sider .collapse-button:hover {
  background-color: #6b717b;
}
