.user_info_content {
  display: flex;
  align-items: center;
  justify-content: right;
  min-width: 110px;
}
.avatar {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.user_info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: max-content;
  height: 60px;
}
.user_info .account {
  border-bottom: 1px solid #f5f5f5;
  display: block;
  margin-bottom: 6px;
  text-align: center;
  padding-bottom: 4px;
  cursor: pointer;
}
.user_info .account .num {
  color: #007fff;
}
